<template>
  <el-dialog
    v-el-drag-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    destroy-on-close
    title="打印"
    width="920px&quot;"
    top="3vh"
    :modal="false"
    @close="$reset('form')">
    <el-button type="primary" @click="printSomething">打印</el-button>

    <div
      id="print"
      ref="content"
    >
      <h1 style="text-align: center;color: black">{{model.typeDesc}}维保作业项目内容和要求</h1>
      <div style="display:flex;justify-content: space-around;margin: 10px 0 5px 0">
        <div style="color: black;width: 350px">项目名称:{{model.maintenanceProjectName}}</div>
        <div style="color: black;width: 350px">工单编号:{{model.orderCode}}</div>
        <div style="color: black;width: 350px">注册代码:{{model.regCode}}</div>
        <div style="color: black;width: 350px">电梯编号:{{model.name}}</div>
      </div>
      <table

        class="tb"
        cellspacing="0"
        cellpadding="0"
        style="border-collapse:collapse; page-break-after: always;
">
        <thead>
          <tr class="tbtr">
            <td class="title" style="color: black">N0.</td>
            <td class="tbtd" style="color: black">作业项目(内容)</td>
            <td class="tbtd" style="color: black">项目作业要求</td>
            <td class="isComplete" style="color: black">是否达标</td>
            <td class="content" style="color: black">存在问题</td>
            <td class="content" style="color: black">照片</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item,index) in model.maintenanceDict" :key="index" class="tbtr">
            <td class="title">{{item.needToPhoto?"⭐":""}}{{index+1}}</td>
            <td class="content">{{item.content}}</td>
            <td class="content">{{item.requirement}}</td>

            <td class="isComplete">
              {{formatChecked(item.checked)}}
            </td>

            <td class="content">{{item.remark}}</td>
            <td class="content">
              <img
                v-if="item.photo"
                :key="index"
                style="width: 200px"
                :src="ip+item.photo"
                @click="openImg(ip+item.photo)"
              />
            </td>
          </tr>
        </tbody>
        <tr class="tbtr">
          <td class="title">更换配件及其其他记录</td>
          <td
            class="tbtd"
            colspan="5"
            align="left"
            valign="top"
            style="height: 150px">
            <div style="float: right;margin-top: 100px">
              维保人员: <span v-if="model.logVoList">
                <span v-for="(item,index) in model.logVoList" :key="index">
                  <img style="width: 80px;height: 80px" :src="ip+item.signature"/>
                  确认日期:
                  <span style="text-decoration: underline;margin-right: 5px">
                    {{item.operatedTime?$moment(item.operatedTime).format("YYYY年MM月DD日 HH:mm:ss"):''}}
                  </span>
                </span>
              </span><span v-else></span>
            </div>
          </td>
        </tr>
        <tr class="tbtr">
          <td
            class="tbtd"
            colspan="3"
            style="border-right: none"
          >
            <div style="margin-left: 40px;display: inline-block;width: 370px">
              工作态度满意度

              <span style="display: inline-block;margin-left: 10px"> <span v-if="model.attitudeScore===5">√</span>很满意</span>
              <span style="display: inline-block;margin-left: 10px"><span v-if="model.attitudeScore===4">√</span>满意</span>
              <span style="display: inline-block;margin-left: 10px"><span v-if="model.attitudeScore===3||model.attitudeScore===2">√</span>一般</span>
              <span style="display: inline-block;margin-left: 10px"><span v-if="model.attitudeScore===0||model.attitudeScore===1">√</span>不满意</span>
            </div>
          </td>
          <td
            class="tbtd"
            align="left"
            colspan="3"
            style="border-left: none">
            <span style="margin-left: 2px;">
              工作质量满意度
              <span style="display: inline-block;margin-left: 10px"> <span v-if="model.qualityScore===5">√</span>很满意</span>
              <span style="display: inline-block;margin-left: 10px"><span v-if="model.qualityScore===4">√</span>满意</span>
              <span style="display: inline-block;margin-left: 10px"><span v-if="model.qualityScore===3||model.qualityScore===2">√</span>一般</span>
              <span style="display: inline-block;margin-left: 10px"><span v-if="model.qualityScore===0||model.qualityScore===1">√</span>不满意</span>
            </span>
          </td>
        </tr>
        <tr class="tbtr">
          <td class="title">用户意见</td>
          <td
            class="tbtd"
            colspan="5"
            align="left"
            valign="top"
            style="height: 100px">
            <span class="content" style="border: none">
              {{model.customerComment}}
            </span>

            <div style="float: right;margin-top: 100px">使用单位电梯安全管理人员(签字): <img v-if="model.usingCompanySignature" style="width: 80px;height: 80px" :src="ip+model.usingCompanySignature"/><span v-else></span>确认日期:<span style="text-decoration: underline;margin-right: 5px">{{model.customerConfirmedTime?$moment(model.customerConfirmedTime).format("YYYY年MM月DD日 HH:mm:ss"):''}}</span></div>
          </td>
        </tr>
      </table>
    </div>
    <el-dialog
      v-el-drag-dialog
      :visible.sync="imgVisible"
      :close-on-click-modal="false"
      title="图片详情"
      width="1000px"
      top="3vh"
      :modal="false">
      <img
        :href="img"
        style="width: 900px"
        :src="img"/>
    </el-dialog>
  </el-dialog>
</template>

<script>
  import print from "print-js";


  export default {
    name: "RepairPrint",
    data() {
      return {id:"",dialogVisible:false,model:{maintenanceDict:[],logVoList:[]},ip:window.config.pictureUrl,imgVisible:false,img:""};
    },
    mounted() {

    },
    methods:{
      addWatermark(content) {
        this.$watermark.set(content,this.$refs.content);
      },
      printSomething() {


        // const table = document.querySelector(".tb");
        // table.style.margin="20px auto";
        // table.style.margin = "0 0";
        print({
          printable:"print",
          type:"html",
          targetStyles:["*"],
          onPrintDialogClose:()=>{

          }});


      },
      openImg(img) {
        console.log(171,img);
        this.imgVisible=true;
        this.img = img;

      },
      open(id) {
        this.id = id;

        this.dialogVisible = true;
        this.getData();

        // this.getRepairOrder();
      },
      formatChecked(checked) {
        let str ="✅";
        if (checked===0) {
          str="❌";
        }else if (checked===2) {
          str="无此项";
        }
        return str;
      },
      getData() {
        if (this.id) {
          this.contentLoading = true;
          this.$http.get(`maintenance/maintain-order/${this.id}/print-preview`).then(data => {
            this.contentLoading = false;
            this.model = data;
            const arr = [];
            if (data.logVoList instanceof Array) {
              data.logVoList.forEach(item=>{
                arr.push(item.operatorName);
              });
            }

            this.addWatermark(arr.join(" ") +" "+ data.completedTime);
            console.log(this.model,103);
            // const arr =[{content:data}];

          });
        }
      },
    },
  };
</script>

<style scoped>
.title{
  width: 50px;
  text-align: center;
  border: 1px solid black;
}
.content{
  width: 200px;
  text-align: center;
  color: black;
  border: 1px solid black;
}
.isComplete{
  width: 150px;
  text-align: center;
  color: black;
  border: 1px solid black;
}
.tbtd{
  padding-left: 4px;
  width: 200px;
  line-height: 23px;
  border: 1px solid black;
  page-break-after: avoid;
}
</style>
